import * as React from "react"
import { css } from "@emotion/react"

const quoteStyle = css`
    place-content: center center;
    padding: 4rem;
    padding-bottom: 2rem;
    background-color:#0192ff ;
    color: white;
    text-align: center;

    h4 {
        font-size: 2rem;
        font-style: italic;
    }

    p {
        font-size: 1.25rem;
    }
`

const ServiceQuote = () => (
    <div css={quoteStyle}>
            
        <h4>Choose Priority One For All Your Restoration Needs!</h4>
        <p>Because everyone deserves dependable and integral service</p>
    </div>
  )
  
  export default ServiceQuote