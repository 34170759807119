import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import styled from "@emotion/styled"
import { css } from "@emotion/react"
import { COLORS, BREAKPOINTS} from "../templates/global"
import { RiSmartphoneLine } from "react-icons/ri";
import { StaticImage } from "gatsby-plugin-image"

const MenuLink = props => {
  // docs: https://reach.tech/router/api/Link

  const linkStyle = css`
    /* margin-left: 1rem; */
    justify-self: flex-end;
    color: black;
    text-decoration: none;
    
    &:hover {
      color: #0192ff;
    }
    
    &:after {
      content: "";
      display: block;
      width: 0%;
      padding-top: 0px;
      border-bottom: 1px solid black; /* TODO change color*/
      transition: all 0.3s ease-in;
    }
    
    &:hover:after {
      width: 100%;
    }
    
    @media screen and (max-width: ${BREAKPOINTS.md}) { /* TODO add breakpoint*/
      margin-left: 0.6rem;
      font-size: 0.9rem;
    }
  `

  const activeLinkStyle = {
    color: `#0192ff`,

    "&:after": {
      width: `100%`,
    },
  }

  const isActive = ({ isCurrent }) => {
    // toggle for active link
    return isCurrent ? { style: { activeLinkStyle } } : { style: { linkStyle } }
  }

  return <Link css={linkStyle} activeStyle={activeLinkStyle} {...props} />
}


const Header = ({ siteTitle }) => (
  <header
    css={css`
      opacity: 0.8;
      border-bottom: solid 2px #0192ff;
      box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.5);
    `}
  >
    <div
      css={css`
        margin: 0 auto;
        max-width: 1200px;
        padding: 1.45rem 1rem;
        display: flex;
        justify-content: space-between;
      `}
    >
      <div css={css`
        display: flex;
        align-items: center;
      `}>
        <div className="site-logo">
        <Link
              to="/"
              css={css`
                color: #0192ff;
                text-decoration: none;
                font-size:1.25rem;
                display:flex;
              `}>
          <StaticImage
            placeholder="blue"
            alt="Priority One Contractor Services"
            src="../images/cropped-brand.png"
            formats={["auto", "png"]}
          />

            <h1 css={css`
              margin:0;
              display:flex;
              display: none;`}>
              
              
                {siteTitle}
            </h1>
          </Link>
        </div>
    </div>
    <nav
    css={css`
      display:flex;
      align-items: center;
      flex-grow:  1;
      justify-content:flex-end;
      margin-right: 1rem;
    `}
    >
      
    {/* <MenuLink activeClassName="active" to="/about">
        About
    </MenuLink> */}
    <MenuLink to="/services" activeClassName="active">Services</MenuLink>
    {/* <MenuLink to="/contact" activeClassName="active">Contact</MenuLink> */}
    {/* <MenuLink to="/rateus" activeClassName="active">Rate Us</MenuLink> */}
       
      
    </nav>
    <div css={css`
      display: flex;
      place-items: center center;
    `}>
      <a href="tel:+13603227700" css={css`text-decoration:none;`}>
        <div css={css`
          color: black;
          align-items:center;
          display: flex;
          /* background-color: #ff8100; */
          background-color: #0192ff;
          padding: 0.35rem;
          border-radius: 0.15rem;
        `}>

            <RiSmartphoneLine css={css`
              height: 2rem;
              width: 2rem;
              color: white;
              `} />
          <div>
              <h4 css={css`margin:0; padding:0; text-align: center; font-size: 0.8rem;`}></h4>
            <span css={css`
              color: white;
              `}>
              360.322.7700 </span>  
          </div>
        </div>
      </a>

    </div>

    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
