/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import styled from "@emotion/styled"
import { css } from "@emotion/react"

import Header from "./header"
import Footer from "./footer"
import "../templates/layout.css"
import ServiceQuote from "./serviceQuote"
// import "../templates/global.css"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  const mainstyle = css`
    min-height: calc(100vh - 10rem); //TODO find exact #s
  `
  return (
    <>
      <Header siteTitle={data.site.siteMetadata?.title || `Priority One Contractors`} />
      <div
        css={css`
          margin: 0 auto;
          padding: 0;
        `}
      >
        <main css={mainstyle}>{children}</main>
        <ServiceQuote />
        <Footer />
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
