import { injectGlobal } from '@emotion/css'


/**
 *
 * Various project level variables
 *
 */

 export const BREAKPOINTS = {
    sm: "425px",
    md: "768px",
    lg: "1024px",
    xl: "1200px",
  }
  
  export const COLORS = {
      primary: "#ff8100"
   
  }