import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import styled from "@emotion/styled"
import { css } from "@emotion/react"

const wrapperStyle = css`
display: grid;
grid-template-columns: 1fr 1fr 1fr;
justify-content: space-around;

div {
    min-height: 8rem;
}
`

const ctaStyle = css`
  border: 2px solid white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  margin-bottom: 2rem;
  padding: 2rem;
  max-width: 800px;

  @media screen and (max-width: 1024px) {
    max-width: 100%;
  }

  

  p {
    font-size: clamp(0.70rem, -0.2rem + 5vw, 1.5rem);
    margin: 0;
  }

  a {
    color: white;
    align-items:center;
    display: block;
    background-color: #0192ff;
    border-color: blue;
    text-decoration:none;
    padding: 1rem 2rem;
    border-radius: 0.15rem;
    transition: all 0.3s;
    
    :hover {
      background-color: darkblue;
      transform: translateY(1px);
    }
  }

  @media screen and (max-width: 600px) {
    flex-direction: column;

    p {
      margin-bottom: 2rem;
    }
  }
`
 const footerCSS = css`
  place-content: center center;
  padding: 4rem;
  padding-bottom: 2rem;
  background-color: #2e2e2e;
  color: white;
  text-align: center;
`

const copy = css`
display: grid;
place-items: center center;`

const Footer = () => (
    <footer css={footerCSS}>
          <div css={ctaStyle}>
            <p>How can we help you?</p>
            <a href="tel:+13603227700">CALL US</a>
          </div>
        {/* <div
         css={wrapperStyle}>
            <div><h4>Priority One Contractors</h4></div>
            <div><h4>SiteLinks</h4></div>
            <div><h4>Contact Us</h4></div>
        </div>
        <div css={copy}>
            © {new Date().getFullYear()}, Site by <a href="https://aaronms.com">Aaronms</a>
        </div> */}
        <p>Priority One Contractor Services © {new Date().getFullYear()}</p>
    </footer>
  )
  
  export default Footer